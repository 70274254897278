import NavBar from "../components/NavBar/NavBar";
import MainSection from "../components/LandingPage/MainSection";

function LandingPage() {
	return (
		<>
			<NavBar />
			<MainSection />
		</>
	);
};
export default LandingPage;